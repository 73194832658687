import React from "react";

import SummarySection from "./sections/SummarySection";
import InternshipSection from "./sections/InternshipSection";
import ProjectSection from "./sections/ProjectSection";

import "./styles/global.css";

const App = () => {
  return (
    <div className="wrapper">
      <SummarySection />
      <InternshipSection />
      <ProjectSection />
    </div>
  );
};

export default App;
