import React, { useEffect, useState } from "react";

import client from "../client.js";
import NotFound from "../components/NotFound.js";
import Project from "../components/Project.js";

const ProjectSection = () => {
  const [projects, setProjects] = useState(null);

  useEffect(() => {
    const init = async () => {
      const allProjects = await client.fetch(`
        *[_type == "project"]{
          title,
          "pictureUrl": picture.asset->url,
          tagline,
          githubLink,
          startDate,
          endDate,
          technologies,
          description,
        } | order(startDate desc)
      `);
      setProjects(allProjects);
      console.log(allProjects);
    };
    init();
  }, []);

  return (
    <div className="body">
      <h1>Projects</h1>
      {projects ? (
        projects.map((project, index) => {
          return <Project props={project} />;
        })
      ) : (
        <NotFound />
      )}
    </div>
  );
};

export default ProjectSection;
