import React, { useEffect, useState } from "react";

import client from "../client.js";

import NotFound from "../components/NotFound.js";
import Internship from "../components/Internship.js";

const InternshipSection = () => {
  const [internships, setInterships] = useState(null);

  useEffect(() => {
    const init = async () => {
      const allInterships = await client.fetch(`
        *[_type == "internship"]{
          companyName,
          "imageUrl": companyPic.asset->url,
          positionTitle,
          tagline,
          companyLink,
          team,
          startDate,
          endDate,
          location,
          technologies,
          description,
        } | order(endDate desc)
      `);
      setInterships(allInterships);
      console.log(allInterships);
      console.log(typeof allInterships[0].startDate);
    };
    init();
  }, []);
  return (
    <div className="body">
      <h1>Internships</h1>
      {internships ? (
        internships.map((internship, index) => {
          return <Internship props={internship} />;
        })
      ) : (
        <NotFound />
      )}
    </div>
  );
};

export default InternshipSection;
