import { PortableText } from "@portabletext/react";
import { useState } from "react";

import { FormatDate } from "../utils/DataFormatter";

const Project = ({ props }) => {
  const [expand, setExpand] = useState(false);

  return (
    <div className="section-wrapper">
      <div
        className="header project"
        role="button"
        style={{
          backgroundImage: `url(${props.pictureUrl})`,
        }}
        onClick={() => {
          setExpand(!expand);
        }}
        onKeyDown={() => {
          setExpand(!expand);
        }}
      >
        <h1>{props.title}</h1>
        <p>{props.tagline}</p>
      </div>
      {expand && (
        <div className="header-body">
          {props.startDate && props.endDate && (
            <p className="information">
              Date Range:{" "}
              {props.startDate != props.endDate
                ? `${FormatDate(props.startDate)} to ${FormatDate(
                    props.endDate
                  )}`
                : `${FormatDate(props.startDate)}`}
            </p>
          )}
          <p className="information">
            Checkout the code:{" "}
            <a
              href={props.githubLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              link
            </a>
          </p>
          {props.technologies.length > 0 && (
            <>
              <p className="list">Technologies: </p>
              {props.technologies.map((technology, index) => {
                return (
                  <>
                    {technology && (
                      <>
                        <p className="list item">{technology}</p>
                        {index != props.technologies.length - 1 && (
                          <p className="list">, </p>
                        )}
                      </>
                    )}
                  </>
                );
              })}
            </>
          )}
          {props.description && (
            <>
              <hr />
              <PortableText value={props.description} />
            </>
          )}
        </div>
      )}
    </div>
  );
};
export default Project;
