import React, { useEffect, useState } from "react";
import { PortableText } from "@portabletext/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

import client from "../client.js";
import NotFound from "../components/NotFound.js";

const SummarySection = () => {
  const [summary, setSummary] = useState(null);

  useEffect(() => {
    const init = async () => {
      const summaryInfo = await client.fetch(`
        *[_type == "summary"]{
          "headshotUrl": headshot.asset->url,
          currentTerm,
          taglines,
          githubProfile,
          linkedinProfile,
          description,
        }
      `);
      setSummary(summaryInfo[0]);
    };
    init();
  }, []);

  return (
    <div className="body">
      {summary ? (
        <div className="body">
          <div className="socials">
            <a
              href={summary.githubProfile}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faGithub} color="white" />
            </a>
            <a
              href={summary.linkedinProfile}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faLinkedinIn} color="white" />
            </a>
          </div>

          <img className="headshot" src={summary.headshotUrl} alt="Headshot" />
          <h1 className="greeting">
            <span className="wave">👋</span>
            Hi my name is McCowan Zhang
          </h1>
          <PortableText value={summary.description} />
        </div>
      ) : (
        <NotFound />
      )}
    </div>
  );
};

export default SummarySection;
