import { useState } from "react";
import { PortableText } from "@portabletext/react";

import { FormatDate } from "../utils/DataFormatter";

const Internship = ({ props }) => {
  const [expand, setExpand] = useState(false);

  return (
    <div className="section-wrapper">
      <div
        className="header internship "
        role="button"
        style={{
          backgroundImage: `url(${props.imageUrl})`,
        }}
        onClick={() => {
          setExpand(!expand);
        }}
        onKeyDown={() => {
          setExpand(!expand);
        }}
      >
        <h1>{props.positionTitle}</h1>
        <p>{props.tagline}</p>
      </div>
      {expand && (
        <div className="header-body">
          {props.startDate && props.endDate && (
            <p className="information">
              Date Range:{" "}
              {props.startDate != props.endDate
                ? `${FormatDate(props.startDate)} to ${FormatDate(
                    props.endDate
                  )}`
                : `${FormatDate(props.startDate)}`}
            </p>
          )}
          {props.team && <p className="information">Team: {props.team}</p>}
          {props.location && (
            <p className="information">Location: {props.location}</p>
          )}
          {props.technologies.length > 0 && (
            <>
              <p className="list">Technologies: </p>
              {props.technologies.map((technology, index) => {
                return (
                  <>
                    {technology && (
                      <>
                        <p className="list item">{technology}</p>
                        {index != props.technologies.length - 1 && (
                          <p className="list">, </p>
                        )}
                      </>
                    )}
                  </>
                );
              })}
            </>
          )}
          {props.description && (
            <>
              <hr />
              <PortableText value={props.description} />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Internship;
