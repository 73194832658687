export const FormatDate = (string) => {
  const array = string.split("-");
  return `${toMonthName(array[1])} ${array[0]}`;
};

const toMonthName = (monthNumber) => {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return date.toLocaleString("en-US", { month: "long" });
};
